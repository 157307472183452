import { compose } from "@reduxjs/toolkit";
import { Button, Result } from "antd";
import React from "react";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";

class NotFound extends React.Component {

    render() {
        const { navigate } = this.props
        return (
            <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={<Button type="primary" onClick={() => navigate(CustomPath.dashboard)}>Back Home</Button>}
            />
        )
    }
}

export default compose(withRouter)(NotFound);
