import { Breadcrumb, Checkbox, Col, Form, Modal, Row, message } from "antd";
import dayjs from 'dayjs';
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { NJVButton, NJVCard, NJVInput, NJVSelect } from "../../component/core-component";
import { AdminPasswordValidator, NumberAndDecimelPointValidatorNullable, PhoneNumberValidator } from "../../core/validator";
import Api from "../../network/api";
import { ApiInstance } from "../../network/api-manager";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";

class PartnershipAdminUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            store_data: props.location?.state?.data || {},
            data: {},
            roleData: [],
            storeAccount: {},
            previewImage: null,
            previewTitle: null,
            fileList: [],
            isLoading: false,
            previewOpen: false, division_collection: [],
            township_collection_map: {},
            township_collection: []
        };
        this.inputRef = React.createRef()
    }

    componentDidMount() {
        this.fetchAllDivision()

    }

    fetchAllDivision = async () => {
        try {
            const response = await ApiInstance(Api.division_get_all, HTTP_METHOD.GET, MEDIA_TYPE.JSON)
            if (response) {
                let divisionList = []
                let townshipMap = {}
                response.map(division => {
                    divisionList.push(division)
                    townshipMap[division.id] = division.townshipDTOList
                })
                this.setState({
                    division_collection: divisionList,
                    township_collection_map: townshipMap
                }, () => this.fetchData(townshipMap))
            }
        } catch (error) {
        }
    }

    fetchData = async (township_map) => {
        try {
            const response = await ApiInstance(Api.partnership_store, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, this.state.store_data.storeId)
            if (response) {
                this.setState(prevState => ({
                    data: response,
                }));
                let partnershipStore = { ...response }

                if (response.userStatus === 'ACTIVE') {
                    partnershipStore = { ...partnershipStore, active: true }
                } else {
                    partnershipStore = { ...partnershipStore, active: false }
                }
                if (partnershipStore.accounts) {
                    partnershipStore.accounts.map((account) => {
                        if (account.accountType === "UNIQUE_ID") {
                            partnershipStore = { ...partnershipStore, primaryPhoneNumber: account.primaryPhoneNumber, password: account.password }
                            this.setState({
                                storeAccount: account
                            })
                        }
                    })
                }
                if (partnershipStore.township) {
                    let township_collection = township_map[partnershipStore.township.division.id]
                    partnershipStore = {
                        ...partnershipStore,
                        divisionId: partnershipStore.township.division ? partnershipStore.township.division.id : null,
                        townshipId: partnershipStore.township ? partnershipStore.township.id : null,
                    }
                    this.setState({
                        township_collection: township_collection
                    })
                }


                this.inputRef.current.setFieldsValue(partnershipStore);
            }
        } catch (error) {
            console.log(error)
        }
    }

    updateGender = (event) => {
        this.setState({
            gender: event.target.value
        })
    }

    // handlePhoneNumberChange = (e) => {
    //     const inputValue = e.target.value;
    //     const numericValue = inputValue.replace(/[^0-9]/g, '');
    //     this.inputRef.current.setFieldsValue({ primaryPhoneNumber: numericValue })
    // };


    updatePartnershipStore = async (values) => {
        const { storeAccount, data } = this.state
        this.setState({
            isLoading: true
        })
        try {
            if (!values.latitude && values.longitude) {
                message.error("Enter latitude")
                return
            } else if (values.latitude && !values.longitude) {
                message.error("Enter longitude")
                return
            }
            let requestData = {
                'id': data.id,
                'firstName': values.firstName,
                'fullName': values.firstName,
                'specialCode': values.specialCode,
                'userStatus': values.active ? "ACTIVE" : "INACTIVE",
                'address1': values.address1,
                'phoneNumber': values.primaryPhoneNumber,
                'latitude': values.latitude ? values.latitude : null,
                'longitude': values.longitude ? values.longitude : null,
                'township': {
                    'id': values.townshipId,
                },
                "accounts": [
                    {
                        "id": storeAccount.id,
                        'accountType': "UNIQUE_ID",
                        "loginId": values.specialCode,
                        "primaryPhoneNumber": values.primaryPhoneNumber,
                        "password": values.password
                    }
                ],
            }
            const response = await ApiInstance(Api.partnership_store, HTTP_METHOD.PUT, MEDIA_TYPE.JSON, requestData)
            if (response?.status === 200) {
                this.props.navigate(CustomPath.partnership_store)
            }
        } catch (error) {
            console.log(error)
        }
        this.setState({
            isLoading: false
        })
    }

    disabledDate = (current) => {
        const currentDate = dayjs();
        const eighteenYearsAgo = currentDate.subtract(18, 'year');
        return current && current > eighteenYearsAgo;
    };

    currentDate = () => {
        const date = dayjs();
        return date.subtract(18, 'year');
    }

    handleDivisionSelect = (value) => {
        const { township_collection_map } = this.state
        this.setState({
            township_collection: township_collection_map[value],
        })
        this.inputRef.current.setFieldsValue({ townshipId: null });
    }


    render() {
        const { isLoading, previewOpen, previewImage, previewTitle, division_collection, township_collection, township_collection_map } = this.state
        return (
            <>

                <Modal
                    open={previewOpen}
                    title={previewTitle}
                    footer={null}
                    onCancel={() => this.setState({ previewOpen: false })}>
                    <img
                        alt="example"
                        style={{
                            width: '100%',
                        }}
                        src={previewImage}
                    />
                </Modal>
                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={22}>
                        <Breadcrumb
                            items={[
                                {
                                    title: 'Home',
                                },
                                {
                                    title: <Link to={CustomPath.partnership_store}>Store</Link>,
                                },
                                {
                                    title: 'Update',
                                }
                            ]}
                        /></Col>
                    <Col span={1} />
                </Row>
                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={22}>
                        <NJVCard
                            bordered={false}
                            title={"Partnership store update"}
                            extra={
                            <NJVButton 
                            onClick={() => this.props.navigate(CustomPath.partnership_store)}
                            type="primary" text="Back" />
                        }
                        >

                            <Form
                                layout="vertical"
                                ref={this.inputRef}
                                onFinish={this.updatePartnershipStore}>
                                <Row gutter={[16, 16]}>
                                    <Col span={12} >
                                        <Form.Item name="firstName" label={"Store Name"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <NJVInput size="large" placeholder={"Enter Store name"} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            name="primaryPhoneNumber"
                                            label={"Phone Number"}
                                            rules={[
                                                { required: true, message: '*(requried)' },
                                                {
                                                    validator: PhoneNumberValidator,
                                                },
                                            ]}>
                                            <NJVInput size="large" placeholder={"979xxxxxxxx"} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} >
                                        <Form.Item name="specialCode" label={"Store Code"} rules={[{ required: true, message: '*(requried)' },{
                                                pattern: /^[a-zA-Z0-9]+$/,message: 'Store Code must contain only letters and numbers'
                                            }]}>
                                            <NJVInput size="large" placeholder={"Enter store code"} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} >
                                        <Form.Item name="password" label={"Password"}
                                            rules={[{ required: true, message: '*(requried)' },
                                            { validator: AdminPasswordValidator }]} style={{ cursor: 'pointer' }}>
                                            <NJVInput ispasswordinput placeholder="Enter password" />
                                        </Form.Item>
                                    </Col>

                                    <Col span={12}>
                                        <Form.Item name="divisionId" label={"Division/State"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <NJVSelect
                                                className="my-location-selector"
                                                placeholder={"Select Division"}
                                                onChange={this.handleDivisionSelect}
                                                options={
                                                    division_collection && division_collection.map((division) => {
                                                        return {
                                                            value: division.id,
                                                            label: division.name,
                                                            type: 'division'
                                                        }
                                                    })
                                                }
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item name="townshipId" label={"Township/City"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <NJVSelect
                                                className="my-location-selector"
                                                placeholder={"Select township"}
                                                onChange={this.handleTownshipSelect}
                                                options={
                                                    township_collection && township_collection?.map((township) => {
                                                        return {
                                                            value: township.id,
                                                            label: township.name,
                                                            type: 'division'
                                                        }
                                                    })
                                                }
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col span={24} >
                                        <Form.Item name="address1" label={"Store Address"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <NJVInput size="large" istextareainput
                                                style={{ height: 60 }} placeholder={"Enter store address"} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} >
                                        <Form.Item name="latitude" label={"Latitude"} rules={[{ validator: NumberAndDecimelPointValidatorNullable }]}>
                                            <NJVInput size="large" placeholder={"Enter lattitude"} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12} >
                                        <Form.Item name="longitude" label={"Longitude"} rules={[{ validator: NumberAndDecimelPointValidatorNullable }]}>
                                            <NJVInput size="large" placeholder={"Enter longitude"} />
                                        </Form.Item>
                                    </Col>

                                    <Col span={24}>
                                        <Form.Item
                                            name="active"
                                            valuePropName="checked">
                                            <Checkbox>Active</Checkbox>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Form.Item style={{ textAlign: 'end' }}>
                                    {/* <Button style={{ marginRight: '20px' }}>Reset</Button> */}
                                    <NJVButton type="primary" htmlType="submit" loading={isLoading} text="Update" />
                                </Form.Item>
                            </Form>
                        </NJVCard>
                    </Col>
                    <Col span={1} />
                </Row>
            </>
        );
    }
}

export default compose(withRouter)(PartnershipAdminUpdate)