import { Navigate } from "react-router-dom"
import { accessToken } from "../redux/auth-slice"

export const RouteGuard = ({ children }) => {
  
    if(accessToken){
        return <Navigate to={'/v1/dashboard'} />
    }else{
        return children
    }
}

export default RouteGuard