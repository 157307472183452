import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
    name: 'auth',
    initialState: {
        accessToken: '',
        refreshToken: '',
        authenticated: false
    },
    reducers: {
        updateAccessToken: (state, action) => {
            state.accessToken = action.payload.accessToken
            state.refreshToken = action.payload.refreshToken
            if (state.accessToken != '' && state.accessToken != undefined) {
                state.authenticated = true
            } else {
                state.authenticated = false
            }
        },
        signOut: (state, action) => {
            state.accessToken = ''
            state.refreshToken = ''
            state.authenticated = false
        }
    },
})

export const { updateAccessToken, signOut} = authSlice.actions;
export const accessToken = (state) => state.auth.accessToken
export const refreshToken = (state) => state.auth.refreshToken
export const authenticated = (state) => state.auth.authenticated
export default authSlice.reducer