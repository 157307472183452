import { DeleteOutlined, LoadingOutlined } from '@ant-design/icons'
import { compose } from '@reduxjs/toolkit'
import { Col, Form, Row, Spin, message } from 'antd'
import i18next from 'i18next'
import React, { Component } from 'react'
import Webcam from 'react-webcam'
import { NJVButton, NJVCard, NJVFloatButton, NJVInput, NJVSelect, TitleLevel3 } from '../../component/core-component'
import { SvgCameraViewFinderFilled } from '../../component/custom-svgIcon'
import Api from '../../network/api'
import { ApiInstance } from '../../network/api-manager'
import { HTTP_METHOD } from '../../network/httpMethod'
import { MEDIA_TYPE } from '../../network/mediaType'
import withRouter from '../../network/with-router'
import CustomPath from '../../routes/custom-path'

const camWidth = 279
const camHeight = 210

class AdvanceOrderForm extends Component {

    constructor(props) {
        super(props)
        this.state = {
            detailData: this.props.location?.state?.data || {},
            senderTownshipList: [],
            receiverTownshipList: [],
            divisionList: [],
            orderData: null,
            deliveryFees: 0,
            division_collection_home_delivery: [],
            township_map_home_delivery: {},
            isFetchingPriceScript: false,
            isFetchingDivision: false,
            fail_message_code: '076',
            showOrderCancelConfirmModal: false,
            orderList: [],
            isOpenFirstCamera: false,
            isOpenSecondCamera: false,
            frontImage: null,
            productImage: null,
            showNRCCameraLoading: false,
            showProductCameraLoading: false,
            showAWBModel: false
        }
        this.formRef = React.createRef();
        this.webcamRef = React.createRef();
        this.productWebcamRef = React.createRef();
        this.photoConstraints = {
            width: 279,
            height: 210,
        }
    }

    componentDidMount() {
        this.fetchDivision();
    }

    fetchDivision = async () => {
        const { orderData } = this.state
        this.setState({
            isFetchingDivision: true
        })
        try {
            const response = await ApiInstance(Api.division_get_all, HTTP_METHOD.GET, MEDIA_TYPE.JSON);
            if (response) {
                this.setState({
                    division_collection: response,
                })
                let divisionListHomeDelivery = []
                let townshipMapHomeDelivery = {}

                response?.map(division => {
                    if (division.active && division.enableHomeDelivery) {
                        divisionListHomeDelivery.push(division)
                        let townshipListHomeDelivery = []
                        division.townshipDTOList && division.townshipDTOList.map(township => {
                            if (township.active && township.enableHomeDelivery) {
                                townshipListHomeDelivery.push(township)
                            }
                        })
                        townshipMapHomeDelivery[division.id] = townshipListHomeDelivery
                    }
                })
                this.setState({
                    divisionList: divisionListHomeDelivery,
                    isFetchingDivision: false,
                    division_collection_home_delivery: divisionListHomeDelivery,
                    township_map_home_delivery: townshipMapHomeDelivery,
                }, () => {
                    const senderTownshipId = this.formRef.current.getFieldValue('senderDivision')
                    const receiverTownshipId = this.formRef.current.getFieldValue('receiverDivision')
                    let senderTownshipList;
                    let receiverTownshipList;
                    if (senderTownshipId) {
                        senderTownshipList = townshipMapHomeDelivery[senderTownshipId.value]
                        this.setState({
                            senderTownshipList
                        })
                    } else {
                        const pickedUpDivisionId = this.props.senderDivision?.id;
                        const divisionObj = { value: this.props.senderDivision?.id, label: this.props.senderDivision?.name, type: 'division' }
                        const senderTownshipList = townshipMapHomeDelivery[pickedUpDivisionId];
                        this.setState({
                            senderTownshipList,
                            orderData: { ...orderData, senderDivision: divisionObj }
                        });
                    }

                    if (receiverTownshipId) {
                        receiverTownshipList = townshipMapHomeDelivery[receiverTownshipId.value]
                        this.setState({
                            receiverTownshipList
                        })
                    }

                })
            }
        } catch (error) {
        }
    }

    getPriceScript = async (senderTownshipId, receiverTownshipId) => {
        try {
            const params = {
                'senderTownshipId': senderTownshipId ? senderTownshipId : 0,
                'receiverTownshipId': receiverTownshipId ? receiverTownshipId : 0
            }
            const response = await ApiInstance(Api.get_price, HTTP_METHOD.GET, MEDIA_TYPE.JSON, params)
            if (response || response === 0) {
                this.setState({
                    deliveryFees: response
                })
            }
        } catch (error) {

        }
        this.setState({
            isFetchingPriceScript: false
        })
    }

    handleUserData = (key, value) => {
        let { township_map_home_delivery, orderData } = this.state;
        let senderTownshipList = [];
        let receiverTownshipList = [];
        orderData = {
            ...orderData,
            [key]: value
        }
        if (key === "senderDivision") {
            senderTownshipList = township_map_home_delivery[value?.value]
            this.setState({
                senderTownshipList
            })
            this.formRef.current.setFieldsValue({ 'senderTownship': null })
        }

        if (key === "receiverDivision") {
            receiverTownshipList = township_map_home_delivery[value?.value]
            this.setState({
                receiverTownshipList
            })
            this.formRef.current.setFieldsValue({ 'receiverTownship': null })
        }

        if (key === "senderTownship" && value?.value) {
            if (orderData?.receiverTownship?.value) {
                this.setState({ isFetchingPriceScript: true }, () => {
                    this.getPriceScript(this.props.senderTownship, orderData?.receiverTownship?.value);
                });
            }
        }
        if (key === "receiverTownship" && value?.value) {
            if (orderData?.senderTownship?.value) {
                this.setState({ isFetchingPriceScript: true }, () => {
                    this.getPriceScript(this.props.senderTownship, value?.value);
                });
            }
        }
        this.setState({
            orderData
        })
    }

    onFinish = (values) => {
        const { orderData, deliveryFees, detailData, frontImage, productImage } = this.state;
        this.setState({
            deliveryFees: 0
        })

        const createdOrder = {
            ...values,
            senderDivision: orderData?.senderDivision ? orderData?.senderDivision : values.senderDivision,
            senderTownship: orderData?.senderTownship ? orderData?.senderTownship : values.senderTownship,
            receiverDivision: orderData?.receiverDivision ? orderData?.receiverDivision : values.receiverDivision,
            receiverTownship: orderData?.receiverTownship ? orderData?.receiverTownship : values.receiverTownship,
            'nrcFront': frontImage,
            'productImage': productImage,
            'deliveryFees': deliveryFees || detailData?.deliveryFees
        }
        this.props.navigate(
            CustomPath.order_confirmation,
            {
                state: {
                    orderData: createdOrder
                }
            });
    }

    onFinishFailed = () => {
        message.destroy()
        message.error("Please fill required fields")
    }

    base64StringToFile = (base64String, fileName) => {
        const byteCharacters = atob(base64String.split(',')[1]);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'image/jpeg' });
        return new File([blob], fileName, { type: 'image/jpeg' });
    };

    canvasCapture = (webcamRef, type) => {
        const canvas = document.createElement('canvas');
        canvas.width = webcamRef.current.video.videoWidth;
        canvas.height = webcamRef.current.video.videoHeight;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(
            webcamRef.current.video,
            0,
            0,
            webcamRef.current.video.videoWidth,
            webcamRef.current.video.videoHeight
        );
        const imageUrl = canvas.toDataURL('image/jpeg', 1.0);
        const fileName = 'image.jpg';
        const imageFile = this.base64StringToFile(imageUrl, fileName);
        if (type === 'nrcImg') {
            this.setState({ frontImage: imageFile })
        } else if (type === 'productImg') {
            this.setState({ productImage: imageFile });
        }
    };

    captureNrc = () => {
        this.canvasCapture(this.webcamRef, 'nrcImg')
    }

    captureProduct = () => {
        this.canvasCapture(this.productWebcamRef, 'productImg')
    }

    onTownshipFilterOption = (input, option) =>
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

    render() {
        const { divisionList, senderTownshipList, receiverTownshipList, isFetchingPriceScript, isOpenFirstCamera, isOpenSecondCamera, photoConstraints, frontImage, productImage, showNRCCameraLoading, isFetchingDivision } = this.state;
      

        return (
            <>
                <Row>
                    <Col lg={6} md={4} xs={1} sm={1} />
                    <Col lg={12} md={16} xs={22} sm={22}>
                        <Row style={{ display: 'flex', alignItems: 'center' }}>
                            <Col span={24}>
                                <TitleLevel3 label={i18next.t("order_create")} style={{ marginTop: 10 }} />
                            </Col>
                        </Row>
                        <Row>
                            <Form
                                ref={this.formRef}
                                onFinish={this.onFinish}
                                layout="vertical"
                                initialValues={{ ...this.state.detailData }}
                                onFinishFailed={this.onFinishFailed}
                            >
                                <Col span={24}>
                                    <NJVCard bordered={false} style={{ paddingInline: 25, paddingBottom: 20, marginBottom: 20 }}>
                                        <Row gutter={[16, 16]} style={{ marginBottom: 15 }}>
                                            <Col span={24}>
                                                <h4 style={{ marginBlock: 8 }}>{i18next.t("sender_info")}</h4>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item label={i18next.t("sender_name")} name="senderName"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: i18next.t("err_msg_enter_sender_name")
                                                        }
                                                    ]}>
                                                    <NJVInput size="large" />
                                                </Form.Item>
                                            </Col>
                                            <Col lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item label={i18next.t("sender_phone_number")} name="senderPhoneNumber"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: i18next.t("err_msg_enter_sender_phone_number")
                                                        },
                                                        {
                                                            pattern: /^[0-9]+$/,
                                                            message: 'Phone Number must be a number!',
                                                        },
                                                        {
                                                            min: 6
                                                        },
                                                        {
                                                            max: 11
                                                        }
                                                    ]}>
                                                    <NJVInput size="large" placeholder="09" onChange={(e) => this.handleUserData('senderPhoneNumber', e.target.value)} />
                                                </Form.Item>
                                            </Col>
                                            <Col lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item name="senderDivision" label={i18next.t("division_state")}>
                                                    <NJVSelect
                                                        defaultValue={this.props.senderDivision?.name}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        loading={isFetchingDivision}
                                                        placeholder={i18next.t("division_state")}
                                                        onChange={(value, object) => this.handleUserData('senderDivision', object)}
                                                        options={
                                                            divisionList && divisionList?.map((division) => {
                                                                return {
                                                                    value: division.id,
                                                                    label: division.name,
                                                                    type: 'division'
                                                                }
                                                            })
                                                        }
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item name={"senderTownship"} label={i18next.t("township_city")}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: i18next.t("choose_sender_township")
                                                        }
                                                    ]}>
                                                    <NJVSelect
                                                        showSearch
                                                        loading={isFetchingDivision}
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        placeholder={i18next.t("township_city")}
                                                        onChange={(_, object) => this.handleUserData('senderTownship', object)}
                                                        filterOption={this.onTownshipFilterOption}
                                                        options={
                                                            senderTownshipList && senderTownshipList?.map((township) => {
                                                                return {
                                                                    value: township.id,
                                                                    label: township.name,
                                                                    type: 'township'
                                                                }
                                                            })
                                                        }
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col lg={24} md={24} sm={24} xs={24}>
                                                <Form.Item name={"additionalAddress"} label={i18next.t("sender_address")}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: i18next.t("err_msg_enter_sender_address")
                                                        }
                                                    ]}>
                                                    <NJVInput istextareainput />
                                                </Form.Item>
                                            </Col>
                                            <Col lg={24} md={24} sm={24} xs={24}>
                                                <Form.Item label={`${i18next.t("sender_nrc")}(Optional)`}>
                                                    {
                                                        !isOpenFirstCamera && !frontImage && (
                                                            <NJVCard name="photo" style={{ backgroundColor: '#fff', width: camWidth, height: camHeight, display: 'flex', wrap: 'flex-wrap', justifyContent: 'center', cursor: 'pointer', alignItems: 'center' }} bordered="false" onClick={() => this.setState({ isOpenFirstCamera: true })}>
                                                                <SvgCameraViewFinderFilled width={50} height={50} color={'gray'} />
                                                                {
                                                                    showNRCCameraLoading ?
                                                                        <LoadingOutlined
                                                                            style={{
                                                                                fontSize: 24,
                                                                            }}
                                                                            spin
                                                                        />
                                                                        :
                                                                        <></>
                                                                }

                                                            </NJVCard>
                                                        )
                                                    }

                                                    {
                                                        isOpenFirstCamera && !frontImage &&
                                                        <div>
                                                            <div>
                                                                <Webcam
                                                                    audio={false}
                                                                    imageSmoothing
                                                                    width={camWidth}
                                                                    height={camHeight}
                                                                    ref={this.webcamRef}
                                                                    screenshotFormat="image/jpeg"
                                                                    photoConstraints={photoConstraints}
                                                                    style={{ borderRadius: '10px', backgroundColor: '#f1f1f1' }}
                                                                />
                                                            </div>
                                                            <br />
                                                            <NJVButton type="primary" onClick={this.captureNrc} text={`${i18next.t("capture_photo")}`} />
                                                        </div>
                                                    }
                                                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                                                        {
                                                            frontImage ?
                                                                <div style={{ position: 'relative' }}>
                                                                    <img src={URL.createObjectURL(frontImage)} alt="Captured" width={280} height={200} style={{ borderRadius: '10px' }} />
                                                                    <NJVFloatButton
                                                                        icon={<DeleteOutlined />}
                                                                        type="primary"
                                                                        style={{
                                                                            position: 'absolute',
                                                                            top: '4px',
                                                                            right: '4px',
                                                                        }}
                                                                        onClick={() => this.setState({ frontImage: null })}
                                                                    />
                                                                </div>
                                                                : <></>
                                                        }
                                                    </div>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </NJVCard>
                                </Col>
                                <Col span={24}>
                                    <NJVCard bordered={false} style={{ paddingInline: 25, paddingBottom: 20, marginBottom: 20 }}>
                                        <Row gutter={[16, 16]} style={{ marginBottom: 15 }}>
                                            <Col span={24}>
                                                <h4 style={{ marginBlock: 8 }}>{i18next.t("receiver_info")}</h4>
                                            </Col>
                                        </Row>
                                        <Row gutter={16}>
                                            <Col lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item name={"receiverName"} label={i18next.t("receiver_name")}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: i18next.t("err_msg_enter_receiver_name")
                                                        }
                                                    ]}>
                                                    <NJVInput size="large" name="name" />
                                                </Form.Item>
                                            </Col>
                                            <Col lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item name={"receiverPhoneNumber"} label={i18next.t("receiver_phone_number")}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: i18next.t("err_msg_enter_receiver_phone_number")
                                                        },
                                                        {
                                                            pattern: /^[0-9]+$/,
                                                            message: 'Phone Number must be a number!',
                                                        },
                                                        {
                                                            min: 6
                                                        },
                                                        {
                                                            max: 11
                                                        }
                                                    ]}>
                                                    <NJVInput size="large" type='text' placeholder="09" onChange={(e) => this.handleUserData('receiverPhoneNumber', e.target.value)} />
                                                </Form.Item>
                                            </Col>
                                            <Col lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item name={"receiverDivision"} label={i18next.t("division_state")}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: i18next.t("choose_receiver_division")
                                                        }
                                                    ]}>
                                                    <NJVSelect
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        loading={isFetchingDivision}
                                                        placeholder={i18next.t("division_state")}
                                                        onChange={(_, object) => this.handleUserData('receiverDivision', object)}
                                                        options={
                                                            divisionList && divisionList?.map((division) => {
                                                                return {
                                                                    value: division.id,
                                                                    label: division.name,
                                                                    type: 'division'
                                                                }
                                                            })
                                                        }
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col lg={12} md={12} sm={24} xs={24}>
                                                <Form.Item name={"receiverTownship"} label={i18next.t("township_city")}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: i18next.t("choose_receiver_township")
                                                        }
                                                    ]}>
                                                    <NJVSelect
                                                        showSearch
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        loading={isFetchingDivision}
                                                        placeholder={i18next.t("township_city")}
                                                        onChange={(_, object) => this.handleUserData('receiverTownship', object)}
                                                        filterOption={this.onTownshipFilterOption}
                                                        options={
                                                            receiverTownshipList && receiverTownshipList?.map((township) => {
                                                                return {
                                                                    value: township.id,
                                                                    label: township.name,
                                                                    type: 'township'
                                                                }
                                                            })
                                                        }
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col lg={24} md={24} sm={24} xs={24}>
                                                <Form.Item name={"addressDetail"} label={i18next.t("receiver_address")}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: i18next.t("err_msg_enter_address")
                                                        }
                                                    ]}>
                                                    <NJVInput
                                                        maxLength={180}
                                                        istextareainput
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24} md={24} sm={24} xs={24}>
                                                <Form.Item name="note" label={i18next.t("note")}>
                                                    <NJVInput istextareainput maxLength={100} style={{ height: 60 }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24} md={24} sm={24} xs={24}>
                                                <div style={{ height: 40, display: 'flex', alignItems: 'center', marginBottom: 16 }}>
                                                    <p>{i18next.t("delivery_fees")}</p>
                                                    {
                                                        isFetchingPriceScript ?
                                                            <Spin
                                                                indicator={
                                                                    <LoadingOutlined
                                                                        style={{
                                                                            fontSize: 24,
                                                                        }}
                                                                        spin
                                                                    />
                                                                }
                                                            />
                                                            :
                                                            <>- {this.state.deliveryFees || this.state.detailData?.deliveryFees} MMK</>
                                                    }
                                                </div>
                                            </Col>
                                            <Col lg={24} md={24} sm={24} xs={24}>
                                                <Form.Item label={`${i18next.t("product_image")}(Optional)`}>
                                                    {
                                                        !isOpenSecondCamera && (!productImage) && (
                                                            <NJVCard name="photo" style={{ backgroundColor: '#fff', width: camWidth, height: camHeight, padding: '10px', display: 'flex', wrap: 'flex-wrap', justifyContent: 'center', cursor: 'pointer', alignItems: 'center' }} bordered="false" onClick={() => this.setState({ isOpenSecondCamera: true })}>
                                                                <SvgCameraViewFinderFilled width={50} height={50} color={'gray'} />
                                                            </NJVCard>
                                                        )
                                                    }
                                                    {
                                                        isOpenSecondCamera && (!productImage) &&
                                                        <div>
                                                            <Webcam
                                                                audio={false}
                                                                width={this.photoConstraints.width}
                                                                height={this.photoConstraints.height}
                                                                ref={this.productWebcamRef}
                                                                screenshotQuality={1}
                                                                screenshotFormat="image/jpeg"
                                                                photoConstraints={photoConstraints}
                                                                style={{ borderRadius: '10px', backgroundColor: '#f1f1f1' }}
                                                            />
                                                            <br />
                                                            <NJVButton type="primary" onClick={this.captureProduct} text={`${i18next.t("capture_photo")}`} style={{ alignSelf: 'end' }} />
                                                        </div>
                                                    }
                                                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }} name="productImg">
                                                        {
                                                            productImage ?
                                                                <div style={{ position: 'relative' }}>
                                                                    <img src={URL.createObjectURL(productImage)} alt="Captured" width={280} height={200} style={{ borderRadius: '10px' }} />
                                                                    <NJVFloatButton
                                                                        icon={<DeleteOutlined />}
                                                                        type="primary"
                                                                        style={{
                                                                            position: 'absolute',
                                                                            top: '4px',
                                                                            right: '4px',
                                                                        }}
                                                                        onClick={() => this.setState({ productImage: null })}
                                                                    />
                                                                </div>
                                                                : <></>
                                                        }
                                                    </div>
                                                </Form.Item>
                                            </Col>
                                            <Col lg={24} md={24} sm={24} xs={24}>
                                                <NJVButton
                                                    htmlType="submit"
                                                    loading={isFetchingPriceScript}
                                                    size='large' style={{ width: '100%' }} type='primary'
                                                    text={i18next.t("confirm")}
                                                />
                                            </Col>
                                        </Row>
                                    </NJVCard>
                                </Col>
                            </Form>
                        </Row>
                    </Col>
                </Row>
            </>
        )
    }
}

export default compose(withRouter)(AdvanceOrderForm)