import { compose } from "@reduxjs/toolkit";
import { Button, Col, Image, Row, Skeleton, Tag, Typography } from "antd";
import dayjs from 'dayjs';
import React from "react";
import { Link } from "react-router-dom";
import { TitleLevel4 } from "../../component/GeneralComponent";
import { SvgArrowLeftFilled } from "../../component/custom-svgIcon";
import { Colors } from "../../component/theme";
import Api from "../../network/api";
import { ApiInstance } from "../../network/api-manager";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";

const { Paragraph } = Typography;

const annocementIcon = require('../../asset/annocement.png');
class NotificationDetail extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            notificationId: props.location.state?.data || null,
            data: {},
            isDataFetching: false
        }
    }

    componentDidMount = () => {
        this.fetchData()
    }

    fetchData = async () => {
        this.setState({
            isDataFetching: true
        })
        try {
            let response = await ApiInstance(Api.notification_history, HTTP_METHOD.GET, MEDIA_TYPE.JSON, null, this.state.notificationId)
                        
            if (response) {
                if (response.createdDate != null) {
                    const createdDate = dayjs(response.createdDate, 'YYYY-MM-DD HH:mm A');
                    if (createdDate) {
                        response = { ...response, 'createdDate': createdDate.format('MMM DD, YYYY hh:mm A') }
                    }
                }
                this.setState({
                    data: response
                })
            }

        } catch (error) {
        }
        this.setState({
            isDataFetching: false
        })
    }

    render() {
        const { data, isDataFetching } = this.state
        return (
            <>
                <Row style={{ marginTop: 50 }}>
                    <Col lg={6} md={6} xs={1} sm={1} />
                    <Col lg={12} md={12} xs={22} sm={22}>
                        <Link to={CustomPath.notification_history}>
                            <Button type="primary"
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                <SvgArrowLeftFilled width={20} height={20} color={'#fff'} /><span style={{ marginLeft: 5 }}>Back</span>
                            </Button>
                        </Link>

                        {
                            isDataFetching ?
                                <Skeleton active />
                                :
                                <>
                                    {
                                        data.notificationType === 'ANNOUNCEMENT' ?
                                            <div style={{ textAlign: 'center', marginBottom: 20 }}>
                                                <Image
                                                    style={{
                                                        marginTop: 5,
                                                        // borderRadius: 9,
                                                        cursor: 'pointer',
                                                        backgroundColor: Colors.primary,
                                                        borderRadius: 100
                                                    }}
                                                    preview={false}
                                                    src={annocementIcon}
                                                    width={100}
                                                    height={100}
                                                />
                                            </div>
                                            :
                                            <></>
                                    }
                                    <Paragraph>
                                        <Tag>
                                            {data.createdDate}
                                        </Tag>
                                    </Paragraph>
                                    <TitleLevel4 label={data.title} />
                                    {
                                        data.photoUrl ?
                                            <Paragraph>
                                                <Image
                                                    style={{
                                                        marginTop: 5,
                                                        borderRadius: 9,
                                                        cursor: 'pointer'
                                                    }}
                                                    preview={false}
                                                    src={data.photoUrl}
                                                    width={'100%'}
                                                />
                                            </Paragraph>
                                            :
                                            <></>
                                    }

                                    <Paragraph>
                                        {data.content}
                                    </Paragraph>

                                </>
                        }
                    </Col>
                </Row>
            </>
        )
    }
}

export default compose(withRouter)(NotificationDetail)