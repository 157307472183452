import { compose } from '@reduxjs/toolkit';
import { Card, Col, DatePicker, Row, Statistic, message } from "antd";
import i18next from 'i18next';
import React, { Component } from 'react';
import CountUp from 'react-countup';
import { NJVButton, NJVCard, NJVDatePicker, NJVInput } from '../component/core-component';
import Theme from '../component/theme';
import Api from '../network/api';
import dayjs from 'dayjs';
import { ApiInstance } from '../network/api-manager';
import { HTTP_METHOD } from '../network/httpMethod';
import { MEDIA_TYPE } from '../network/mediaType';
import withRouter from "../network/with-router";
import { updateSelectedMenu } from '../redux/cookie-slice';
import { updateProfile } from '../redux/profile-slice';
import CustomPath from '../routes/custom-path';

const { RangePicker } = DatePicker;

const StatisticCard = ({ name,count }) => (
    <Card
        style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none', borderRadius: 20 }}
        bordered={false}
    >
        <Statistic
            title={<span style={{ fontWeight: '600', fontSize: 16 }}>{name}</span>}
            value={count}
            valueStyle={{
                color: '#3f8600',
            }}
            formatter={(value) => <CountUp end={value} separator=',' />}
        />
    </Card>
);

class StoreDashboardPage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            totalCommission: 0,
            totalRevenue: 0,
            handOnParcel: 0,
            ongoingParcel: 0,
            completeParcel: 0,
            cancelParcel: 0,
            totalParcel: 0,
            isFetchingData: false,
            isSearchingData: false,
            filterData: {}
        }
    }

    componentDidMount() {
        this.fetchData()
        this.fetchProfile()
        this.props.dispatch(updateSelectedMenu("14"))
    }

    fetchProfile = async () => {
        try {
            const response = await ApiInstance(Api.partnership_profile, HTTP_METHOD.GET, MEDIA_TYPE.JSON);
            if (response) {
                this.props.dispatch(updateProfile(response));
            }
        } catch (error) {
        }
    }

    handleFilter = (event) => {
        this.setState({ filter_name: event.target.value })
    }

    trackingSearch = async () => {
        const { filter_name } = this.state
        if (!filter_name || filter_name.length < 15) {
            message.error("Please enter tracking number first.")
            return
        }
        const data = {
            id: filter_name,
            route: CustomPath.dashboard
        }
        this.props.navigate(
            CustomPath.order_detail,
            { state: { data: data } }
        );
    }

    fetchData = async () => {
        let { filterData } = this.state;

        try {
            this.setState({
                isFetchingData: true
            })
            let params = {}

            const {fromDate, toDate} = filterData;
            if(filterData){
                if ((fromDate && !toDate) || (!fromDate && toDate) || (fromDate > toDate)) {
                    message.error("Invalid date")
                    return
                }
                if (filterData.fromDate) {
                    filterData = { ...filterData, 'fromDate': filterData.fromDate.format("YYYY-MM-DD") }
                }
                if (filterData.toDate) {
                    filterData = { ...filterData, 'toDate': filterData.toDate.format("YYYY-MM-DD") }
                }
                if (filterData['fromDate-toDate']) {
                    filterData['fromDate-toDate'] = `${dayjs(filterData['fromDate-toDate'][0]).format("YYYY-MM-DD")}&${dayjs(filterData['fromDate-toDate'][1]).format("YYYY-MM-DD")}`;
                } else {
                    filterData = { ...filterData, 'fromDate': null, 'toDate': null }
                }
                params = {
                    ...filterData
                }
            }

            const response = await ApiInstance(Api.partnership_store_report, HTTP_METHOD.GET, MEDIA_TYPE.JSON, params);
            const { handOnParcel, ongoingParcel, completeParcel, cancelParcel, totalParcel, totalCommission, totalRevenue } = response;
            if (response) {
                this.setState({
                    totalCommission,
                    totalRevenue,
                    handOnParcel,
                    ongoingParcel,
                    completeParcel,
                    cancelParcel,
                    totalParcel,
                })
            }
        } catch (error) {
            console.log(error);
        }finally {
            this.setState({
                isFetchingData: false,
                isSearchingData: false,
            });
        }
    }

    search = () => {
        this.setState({
            isSearchingData: true
        }, () => {
            this.fetchData()
        })
    }

    updateFilterData = (key,value) => {
        let { filterData } = this.state;

        filterData = {
            ...filterData,
            [key]: value
        }

        if (key === 'fromDate-toDate' && value) {
            filterData = { ...filterData, fromDate: value[0], toDate: value[1], 'fromDate-toDate': value }
            this.setState({
                filterData
            })
        }

        this.setState({
            filterData
        })
    }

    render() {
        const { totalParcel, totalCommission, totalRevenue, handOnParcel, ongoingParcel, completeParcel, cancelParcel, isSearchingData } = this.state

        return (
            <Row style={{ marginTop: 20 }}>
                <Col span={1} />
                <Col span={22}>
                    <Row gutter={[16, 16]}>
                        <Col span={24} style={{ marginBottom: '10px' }}>
                            <Row gutter={16}>
                                <Col lg={20} md={20} sm={18} xs={18}>
                                    <NJVInput
                                        size='large'
                                        style={{ backgroundColor: '#f5f5f5' }} placeholder={i18next.t("enter_tracking_id")} onChange={this.handleFilter} />
                                </Col>
                                <Col lg={4} md={4} sm={6} xs={6}>
                                    <NJVButton size="large" type="primary" style={{ backgroundColor: Theme.colors.primary, width: '100%' }} onClick={() => this.trackingSearch()} text={i18next.t("search")} />
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24} style={{ marginTop: '20px'}}>
                            <NJVCard
                                style={{ paddingTop: 15, paddingBottom: 15 }}
                                bordered={false}
                            >
                                <Row gutter={[24, 24]}>
                                    {/* <Col span={8}>
                                        <FormLabelOptional label={i18next.t("from_date")} />
                                        <NJVDatePicker
                                            onChange={(value) => this.updateFilterData('fromDate', value)}
                                            style={{ width: '100%' }}
                                            placeholder={i18next.t("from_date")} />
                                    </Col>
                                    <Col span={8}>
                                        <FormLabelOptional label={i18next.t("to_date")} />
                                        <NJVDatePicker
                                            onChange={(value) => this.updateFilterData('toDate', value)}
                                            style={{ width: '100%' }}
                                            placeholder={i18next.t("to_date")} />
                                    </Col>       */}
                                     <Col lg={12} md={12} sm={12} xs={24}>
                                        <RangePicker size="large" style={{ width: '100%', background: '#fff', border: 'none' }}
                                            allowClear
                                            value={this.state.filterData['fromDate-toDate']}
                                            onChange={(value) => this.updateFilterData('fromDate-toDate', value)} />
                                    </Col>
                                    <Col lg={8} md={8} sm={12} xs={24}>
                                            <NJVButton
                                                loading={isSearchingData}
                                                size="large"
                                                style={{ width: '100%' }}
                                                type="primary" onClick={() => this.search()} text={i18next.t("search")} />
                                    </Col>  
                                    <Col lg={2} md={2} sm={0} xs={0}></Col>                            
                                </Row>
                            </NJVCard>
                        </Col>
                        <Col lg={8} md={8} xs={12} sm={12}>
                            <StatisticCard name={i18next.t("total_revenue")} count={totalRevenue} />
                        </Col>
                        <Col lg={8} md={8} xs={12} sm={12}>
                            <StatisticCard name={i18next.t("total_commission")} count={totalCommission} />
                        </Col>
                        <Col lg={8} md={8} xs={12} sm={12}>
                            <StatisticCard name={i18next.t("total_parcel")} count={totalParcel} />
                        </Col>
                    </Row>
                    <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
                        <Col span={24}>
                            <Row gutter={[16,16]}>
                                <Col lg={6} md={6} xs={12} sm={12}>
                                    <StatisticCard name={i18next.t("inStore_parcel")} count={handOnParcel} />
                                </Col>
                                <Col lg={6} md={6} xs={12} sm={12}>
                                    <StatisticCard name={i18next.t("inProgress_parcel")} count={ongoingParcel} />
                                </Col>
                                <Col lg={6} md={6} xs={12} sm={12}>
                                    <StatisticCard name={i18next.t("complete_parcel")} count={completeParcel} />
                                </Col>
                                <Col lg={6} md={6} xs={12} sm={12}>
                                    <StatisticCard name={i18next.t("cancel_parcel")} count={cancelParcel} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Col>
            </Row>
        )
    }
}

export default compose(withRouter)(StoreDashboardPage)

