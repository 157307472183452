import { compose } from "@reduxjs/toolkit";
import { Col, Image, Modal, Row, Skeleton, Steps, Tag, message } from "antd";
import dayjs from 'dayjs';
import i18next from "i18next";
import Lottie from "lottie-react";
import React from "react";
import ReactToPrint from "react-to-print";
import { NJVButton, NJVCard, NJVSpacer } from "../../component/core-component";
import { SvgCheck, SvgOrderIncomplete } from "../../component/custom-svgIcon";
import Theme, { Colors } from "../../component/theme";
import Api from "../../network/api";
import { ApiInstance } from "../../network/api-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";
import AirWayBillSlip from "./airway-bill-slip";
// import Lottie from "lottie-react";
import NoDataFoundAnimation from '../../animate-lottie/lottie-not-data-found.json';

import IssueCloseAnimation from '../../animate-lottie/lottie-issue-close.json';
import IssuePendingAnimation from '../../animate-lottie/lottie-issue-pending.json';
import IssueCompleteAnimation from '../../animate-lottie/lottie-success.json';
import { STAFF } from "../../core/constant";
import { LOC_getUserType } from "../../core/local-storage";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { Link } from "react-router-dom";

const containerW = '3in'
const containerH = '4.5in'

const PENDING_PICK_UP = 'PENDING_PICK_UP'
const SUCCESSFUL_PICKUP = 'SUCCESSFUL_PICKUP'
const SUCCESSFUL_DELIVERY = 'SUCCESSFUL_DELIVERY'
const ARRIVED_AT_SORTING_HUB = 'ARRIVED_AT_SORTING_HUB'
const EN_ROUTE_TO_SORTING_HUB = 'EN_ROUTE_TO_SORTING_HUB'
const ON_VEHICLE_FOR_DELIVERY = 'ON_VEHICLE_FOR_DELIVERY'
const COMPLETED = 'COMPLETED'
const PENDING_RESCHEDULE = 'PENDING_RESCHEDULE'
const RETURNED_TO_SENDER = 'RETURNED_TO_SENDER'
const CANCELLED = 'CANCELLED'

const eventMap = {
    'PENDING_PICK_UP': 0,
    'SUCCESSFUL_PICKUP': 1,
    'ARRIVED_AT_SORTING_HUB': 2,
    'EN_ROUTE_TO_SORTING_HUB': 3,
    'ON_VEHICLE_FOR_DELIVERY': 4,
    'SUCCESSFUL_DELIVERY': 5,
    'COMPLETED': 6,
    'PENDING_RESCHEDULE': 7,
    'RETURNED_TO_SENDER': 8,
    'CANCELLED': 9
}




const NJVLabel = ({ label }) => {
    return (
        <>
            <span style={{ fontSize: 13, fontWeight: '600', color: 'gray' }}>{label}</span>
            <br />
        </>)

}

const NJVValue = ({ value }) => {
    return (
        <>
            <span style={{ fontSize: 16, fontWeight: '500', color: 'black' }}>{value}</span>
            <br />
        </>)
}

const StepTitileStyle = ({ title, date, event }) => {
    return (<>
        <>
            {
                date ?
                    <div style={{ width: '100%' }}>
                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 5 }}>
                            <span>{title}</span>
                            <div><Tag>{date}</Tag></div>
                        </div>

                        {
                            event.deliveryException ?
                                <>
                                    {
                                        event.deliveryException.failure_reason ?
                                            <div style={{ color: 'red' }}>Fail Reason : {event.deliveryException.failure_reason}</div>
                                            :
                                            <></>
                                    }


                                    {
                                        event.deliveryException.proof && event.deliveryException.proof.image_uris ?
                                            <div style={{ display: 'flex' }}>
                                                {
                                                    event.deliveryException.proof.image_uris.map(image =>
                                                        <div style={{ marginLeft: 5 }}>
                                                            <Image src={image} width={50} height={50} style={{ borderRadius: 6 }} />
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            :
                                            <></>
                                    }
                                </>

                                :
                                <></>
                        }
                        {
                            event.deliveryInformation ?

                                <div style={{ display: 'flex' }}>
                                    {
                                        event.deliveryInformation.proof && event.deliveryInformation.proof.image_uris ?
                                            <>
                                                {
                                                    event.deliveryInformation.proof.image_uris.map(image =>
                                                        <div style={{ marginLeft: 5 }}>
                                                            <Image src={image} width={50} height={50} style={{ borderRadius: 6 }} />
                                                        </div>
                                                    )
                                                }
                                            </>
                                            :
                                            <></>
                                    }
                                    {
                                        event.deliveryInformation.proof && event.deliveryInformation.proof.signature_uri ?
                                            <div style={{ marginLeft: 5 }}>
                                                <Image src={event.deliveryInformation.proof.signature_uri} width={50} height={50} style={{ borderRadius: 6, backgroundColor: 'white' }} />
                                            </div>
                                            :
                                            <></>

                                    }
                                </div>
                                :
                                <></>
                        }
                        {
                            event.pickupException ?
                                <>
                                    {
                                        event.pickupException.failure_reason ?
                                            <div style={{ color: 'red' }}>Fail Reason : {event.pickupException.failure_reason}</div>
                                            :
                                            <></>
                                    }
                                </>

                                :
                                <></>
                        }
                    </div >
                    :
                    <>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}><span>{title}</span></div>
                    </>
            }
        </>
    </>)
}

const category = [{ label: "Request Support", value: "Request Support", key: 'request_support' }, { label: "Complaint Issue", value: "Complaint Issue", key: 'complaint_issue' }]
const subCategory = {
    "Request Support": [
        { label: "Return to Sender", value: "Return to Sender", key: 'ticket_return_to_sender' },
        { label: "Bus Gate Pickup", value: "Bus Gate Pickup", key: 'ticket_bus_gate_pickup' },
        { label: "Change Phone Number", value: "Change Phone Number", key: 'ticket_change_phone_number' },
        { label: "Change Address", value: "Change Address", key: 'ticket_change_address' },
        { label: "Change COD Amount", value: "Change COD Amount", key: 'ticket_change_cod_amount' },
        { label: "Change Delivery Date", value: "Change Delivery Date", key: 'ticket_delivery_date' },
    ],
    "Complaint Issue": [
        { label: "Remittance Issue", value: "Remittance Issue", key: 'ticket_remittance_issue' },
        { label: "Parcel Loss", value: "Parcel Loss", key: 'ticket_parcel_loss' },
        { label: "Parcel Damage", value: "Parcel Damage", key: 'ticket_parcel_damage' },
        { label: "Pick Up Issue", value: "Pick Up Issue", key: 'ticket_pickup_issue' },
        { label: "Delivery Lead Time Issue", value: "Delivery Lead Time Issue", key: 'ticket_delivery_lead_time_issue' },
        { label: "Bus Gate Vouchers", value: "Bus Gate Vouchers", key: 'ticket_bus_gate_vouchers' },
        { label: "KG/CM Issue", value: "KG/CM Issue", key: 'ticket_kg_cm_issue' },
        { label: "Service Attitude", value: "Service Attitude", key: 'ticket_service_attitude' }
    ]
}

class OrderDetail extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            order_object: props.location.state?.data || {},
            data: {},
            orderEvent: [],
            currentStep: 0,
            orderSteps: [],
            sub_category_collection: [],
            fileList: [],

            isDataFetching: false,
            viewPhoto: false,
            showInfoModal: false,
            showOrderCancelConfirmModal: false,
            isCancelling: false,
            showIssueProgressingModal: false,
            isFormSubmittedSuccess: false,
            is_submit_request: false,


            form_category: null,
            form_sub_category: null,
            form_reason: null,

            confirming_tracking_number: false
        }
        this.inputRef = React.createRef();

    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async () => {
        const { order_object } = this.state
        const { id, securityCode } = this.props;

        try {
            let param;
            if (id && securityCode) {
                param = {
                    trackingNumber: id
                }
            } else {
                param = {
                    trackingNumber: order_object.id
                }
            }
            this.setState({
                isDataFetching: true
            })
            const response = await ApiInstance(Api.order_get_by_tracking_number, HTTP_METHOD.GET, MEDIA_TYPE.JSON, param)
            if (response) {
                let data = response
                let fromAddress = data.fromAddress
                let toAddress = data.toAddress
                if (data && data.fromTownship) {
                    fromAddress = fromAddress + "," + data.fromTownship.name
                    if (data.fromTownship.division) {
                        fromAddress = fromAddress + "," + data.fromTownship.division.name
                    }
                }
                if (data && data.toTownship) {
                    toAddress = toAddress + "," + data.toTownship.name
                    if (data.toTownship.division) {
                        toAddress = toAddress + "," + data.toTownship.division.name
                    }
                }
                data = { ...data, senderAddress: fromAddress, recipientAddress: toAddress }

                if (data.createdDate != null) {
                    const createdDate = dayjs(data.createdDate, 'YYYY-MM-DD HH:mm:ss');
                    if (createdDate) {
                        data = { ...data, 'createdDate': createdDate.format('YYYY-MMM-DD hh:mm A') }
                    }
                }
                let currentStep = 0
                let status = PENDING_PICK_UP
                let timeMap = {}
                let steps = []

                response.orderEvents && response.orderEvents.map((orderEvent, index) => {
                    status = orderEvent?.orderStatus
                    let formatedEventTime = ''
                    if (orderEvent.timestamp) {
                        const eventTime = dayjs(orderEvent.timestamp, 'YYYY-MM-DD HH:mm:ss');
                        if (eventTime) {
                            timeMap[orderEvent.orderStatus] = eventTime.format('YYYY-MMM-DD hh:mm A')
                            formatedEventTime = eventTime.format('YYYY-MMM-DD hh:mm A')
                        }
                    }

                    if (status === CANCELLED) {
                        steps.push(
                            {
                                key: index + 1,
                                title: <StepTitileStyle title={orderEvent?.status} date={formatedEventTime} event={orderEvent} />,
                                icon: <SvgOrderIncomplete width={25} height={25} color={'red'} />
                            }
                        )
                    } else {
                        steps.push(
                            {
                                key: index + 1,
                                title: <StepTitileStyle title={orderEvent?.status} date={formatedEventTime} event={orderEvent} />,
                                icon: <SvgCheck width={25} height={25} color={'green'} />
                            }
                        )
                    }

                    if (orderEvent.orderStatus === 'PENDING_RESCHEDULE') {
                        data = { ...data, comment: orderEvent.comments }
                    }

                    currentStep = index + 1
                })

                this.setState({
                    data: data,
                    orderEvent: response.orderEvents ? response.orderEvents : [],
                    currentStep: currentStep,
                    orderSteps: steps
                })
            }
        } catch (error) {
            if (error.response?.status === 500) {
                this.setState({
                    data: null
                })
            }
        }
        this.setState({
            isDataFetching: false,
        })
    }

    cancelOrder = async (tracking_number) => {
        this.setState({
            isCancelling: true
        })
        try {
            await ApiInstance(Api.order, HTTP_METHOD.DELETE, MEDIA_TYPE.JSON, null, tracking_number, "Your order has been canceled.")
        } catch (error) {
            console.log(error);            
        }
        this.setState({
            showOrderCancelConfirmModal: false,
            isCancelling: false
        })

        this.fetchData()
    }

    onchangeCategory = (value) => {
        this.setState({
            form_sub_category: null,
            form_category: value,
            sub_category_collection: subCategory[value]
        })
    }

    handleChange = ({ fileList: newFileList }) => this.setState({
        fileList: newFileList
    })

    airwaybill = () => {
        this.props.navigate(CustomPath.airwaybill, { state: { data: this.state.successfullOrderList, route: CustomPath.order_create } })
    }

    render() {
        const { data, isDataFetching, currentStep, orderSteps, showIssueProgressingModal, showOrderCancelConfirmModal, isCancelling, order_object } = this.state
        const customSizeStyles = {
            width: containerW,
            height: containerH,
        };

        const { id, securityCode, prev, next, steps, current } = this.props;

        return (
            <>
                <Modal
                    title={"Confirmation of Order Cancellation"}
                    centered
                    open={showOrderCancelConfirmModal}
                    onCancel={() => this.setState({ showOrderCancelConfirmModal: false })}
                    footer={null}
                    closeIcon={null}
                    className='custom-modal'
                >
                    <p>Are you sure you want to cancel this order?</p>
                    <NJVButton type='primary' loading={isCancelling} onClick={() => this.cancelOrder(data.trackingNumber)} text="Cancel Now" />
                </Modal>

                <Modal
                    title={"Issue Status"}
                    centered
                    open={showIssueProgressingModal}
                    onCancel={() => this.setState({ showOrderCancelConfirmModal: false })}
                    footer={null}
                    closeIcon={null}
                    className='custom-modal'
                >
                    {
                        data && data.issue && data.issue?.status === 'PENDING' ?
                            <div style={{ textAlign: 'center' }}>
                                <Lottie
                                    style={{ height: '200px' }}
                                    animationData={IssuePendingAnimation}
                                    loop={true}
                                    autoplay={true}
                                />
                                <div style={{ margin: '15px 0px' }}>
                                    {i18next.t("lb_ticket_processing")}
                                </div>
                                <NJVButton type="primary" style={{ backgroundColor: Theme.colors.primary }} onClick={() => this.setState({ showIssueProgressingModal: false })} text="Close" />
                            </div>
                            :
                            <></>
                    }
                    {
                        data && data.issue && data.issue?.status === 'CLOSED' ?
                            <div style={{ textAlign: 'center' }}>
                                <Lottie
                                    style={{ height: '200px' }}
                                    animationData={IssueCloseAnimation}
                                    loop={true}
                                    autoplay={true}
                                />
                                <div style={{ margin: '15px 0px' }}>
                                    {i18next.t("lb_ticket_closed")}
                                </div>
                                <NJVButton type="primary" style={{ backgroundColor: Theme.colors.primary }} onClick={() => this.setState({ showIssueProgressingModal: false })} text="Close" />
                            </div>

                            :
                            <></>
                    }
                    {
                        data && data.issue && data.issue?.status === 'COMPLETE' ?
                            <div style={{ textAlign: 'center' }}>
                                <Lottie
                                    style={{ height: '200px' }}
                                    animationData={IssueCompleteAnimation}
                                    loop={true}
                                    autoplay={true}
                                />
                                <div style={{ margin: '15px 0px' }}>
                                    {i18next.t("lb_ticket_resolve")}
                                </div>
                                <NJVButton type="primary" style={{ backgroundColor: Theme.colors.primary }} onClick={() => this.setState({ showIssueProgressingModal: false })} text="Close" />
                            </div>
                            :
                            <></>
                    }
                </Modal>
                <Row style={{ paddingBottom: 1000 }}>
                    <Col lg={6} md={6} xs={1} sm={1} />
                    <Col lg={12} md={12} xs={22} sm={22}>
                        <Row style={{ display: 'flex', alignItems: 'center' }}>
                            <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                                <h2>{i18next.t("order_detail")}</h2>
                                {/* <>
                                    {
                                        data !== null && data !== undefined ?
                                            <>
                                                {
                                                    data && data.issue && (data.issue.status === 'PENDING' || data.issue.status === 'CLOSED' || data.issue.status === 'COMPLETE') ?
                                                        <Button type="primary" shape="circle" onClick={() => {
                                                            this.setState({ showIssueProgressingModal: true })
                                                        }} style={{ marginLeft: 10 }}>
                                                            <SvgInfo width={10} height={10} color={'#fff'} />
                                                        </Button>
                                                        :
                                                        <Button type="primary" shape="circle" onClick={() => {
                                                            this.setState({ showInfoModal: true })
                                                        }} style={{ marginLeft: 10 }}>
                                                            <SvgInfo width={10} height={10} color={'#fff'} />
                                                        </Button>
                                                }
                                            </>
                                            :
                                            <></>
                                    }
                                </> */}
                            </Col>

                            <Col span={12} style={{ textAlign: 'right' }}>
                                {
                                    id && securityCode ? (
                                        <>
                                            {current === steps.length - 1 && (
                                                <NJVButton type="primary" onClick={() => message.success('Processing complete!')} text="Done" />
                                            )}
                                            {current > 0 && (
                                                <NJVButton
                                                    style={{
                                                        margin: '0 8px',
                                                    }}
                                                    onClick={() => prev()}
                                                    text="Previous"
                                                />
                                            )}
                                            {current < steps.length - 1 && (
                                                <NJVButton type="primary" onClick={() => next()} text="Next" />
                                            )}                                            
                                        </>
                                    )
                                        :
                                        <Link to={order_object.route} relative="path">
                                            <NJVButton type="primary" text="Back" />
                                        </Link>
                                }
                            </Col>
                        </Row>
                        <NJVSpacer height={20} />
                        {
                            isDataFetching ?
                                <>
                                    <Row>
                                        <Col span={24}>
                                            <Skeleton active />
                                        </Col>
                                    </Row>
                                </>
                                :
                                <>
                                    {
                                        data ?
                                            <div>
                                                {
                                                    data.orderStatus === 'CANCELLED' ?
                                                        <>
                                                            <Row>
                                                                <Col span={24}>
                                                                    <NJVCard
                                                                        style={{ backgroundColor: "red" }}
                                                                        bordered={false}>
                                                                        <span style={{ fontSize: 13, fontWeight: '600', color: 'white' }}>This order has been cancelled</span>
                                                                    </NJVCard>
                                                                </Col>
                                                            </Row>
                                                            <NJVSpacer height={20} />
                                                        </>
                                                        :
                                                        <>
                                                        </>
                                                }
                                                <Row gutter={[16, 16]}>
                                                    <Col span={24}>
                                                        <NJVCard
                                                            bordered={false}>
                                                            <NJVLabel label={i18next.t("tracking_number")} />
                                                            <NJVValue value={data.trackingNumber} />
                                                            <NJVSpacer height={30} />
                                                            {
                                                                data.fromAddress && data.fromTownship ?
                                                                    <>
                                                                        <NJVLabel label={i18next.t("pickup_address")} />
                                                                        <NJVValue value={`${data.fromAddress}, ${data.fromTownship.name}, ${data.fromTownship?.division?.name} `} />
                                                                        <NJVSpacer height={30} />
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }
                                                            <NJVLabel label={i18next.t("pickup_store")} />
                                                            <NJVValue value={data?.shipper?.fullName} />
                                                        </NJVCard>
                                                    </Col>
                                                    <Col span={24}>
                                                        <NJVCard
                                                            bordered={false}>
                                                            <NJVLabel label={i18next.t("sender_name")} />
                                                            <NJVValue value={`${data.senderName}`} />
                                                            <NJVSpacer height={30} />
                                                            <NJVLabel label={i18next.t("sender_phone_number")} />
                                                            <NJVValue value={data.senderPhoneNumber} />
                                                            <NJVSpacer height={30} />
                                                            <NJVLabel label={i18next.t("sender_address")} />
                                                            <NJVValue value={data.fromAddress} />
                                                        </NJVCard>
                                                    </Col>
                                                    <Col span={24}>
                                                        <NJVCard
                                                            bordered={false}>
                                                            <NJVLabel label={i18next.t("receiver_name")} />
                                                            <NJVValue value={`${data.recipientName}`} />
                                                            <NJVSpacer height={30} />
                                                            <NJVLabel label={i18next.t("receiver_phone_number")} />
                                                            <NJVValue value={data.recipientPhoneNumber} />
                                                            <NJVSpacer height={30} />
                                                            {
                                                                data.toAddress && data.toTownship ?
                                                                    <>
                                                                        <NJVLabel label={i18next.t("receiver_address")} />
                                                                        <NJVValue value={`${data.toAddress}, ${data.toTownship.name}, ${data.toTownship.division.name}`} />
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }
                                                        </NJVCard>
                                                    </Col>
                                                    <Col span={24}>
                                                        <NJVCard
                                                            bordered={false}>
                                                            <Row gutter={[16, 16]}>
                                                                <Col span={24}>
                                                                    <NJVLabel label={i18next.t("pickup_time")} />
                                                                    <NJVValue value={`${data.pickupDate}`} />
                                                                </Col>
                                                                <Col span={24}>
                                                                    <NJVLabel label={i18next.t("order_type")} />
                                                                    <NJVValue value={`${data.orderType}`} />
                                                                </Col>
                                                                {
                                                                    data.paymentOption ?
                                                                        <Col span={24}>
                                                                            <NJVLabel label={i18next.t("payment_option")} />
                                                                            <NJVValue value={`${data.paymentOption}`} />
                                                                        </Col>
                                                                        :
                                                                        <></>

                                                                }
                                                                <Col span={24}>
                                                                    <NJVLabel label="Note" />
                                                                    <NJVValue value={`${data.note ? data.note : '-'} `} />
                                                                </Col>
                                                                <Col span={24}>
                                                                    <NJVLabel label={i18next.t("cod")} />
                                                                    {
                                                                        data.codAmount ?
                                                                            <NJVValue value={`${data.codAmount} MMK`} />
                                                                            :
                                                                            <NJVValue value={`0 MMK`} />
                                                                    }
                                                                </Col>
                                                                <Col span={24}>
                                                                    <NJVLabel label={i18next.t("delivery_fees")} />
                                                                    {
                                                                        data.deliveryCharges ?
                                                                            <NJVValue value={`${data.deliveryCharges} MMK`} />
                                                                            :
                                                                            <NJVValue value={`0 MMK`} />
                                                                    }
                                                                </Col>
                                                                {
                                                                    data && data.updateDeliveryCharges ?
                                                                        <Col span={24}>
                                                                            <NJVLabel label="Updated Delivery Charges" />
                                                                            <NJVValue value={`${data.updateDeliveryCharges}`} />
                                                                        </Col> :
                                                                        <></>
                                                                }
                                                                {
                                                                    data && data.updateCodAmount ?
                                                                        <Col span={24}>
                                                                            <NJVLabel label="Updated Cod Amount" />
                                                                            <NJVValue value={`${data.updateCodAmount}`} />
                                                                        </Col> :
                                                                        <></>
                                                                }
                                                                <Col span={24}>
                                                                    <NJVLabel label={i18next.t("status")} />
                                                                    <NJVSpacer height={10} />
                                                                    <Steps
                                                                        className="step-full-width-title"
                                                                        size="small"
                                                                        current={currentStep}
                                                                        direction="vertical"
                                                                        items={orderSteps}
                                                                    />
                                                                </Col>
                                                                {
                                                                    data.comment ?
                                                                        <Col span={24}>
                                                                            <NJVLabel label={i18next.t("fail_reason")} />
                                                                            <NJVValue value={data.comment} />
                                                                        </Col>
                                                                        :
                                                                        <></>
                                                                }
                                                                <Col span={24}></Col>
                                                                <Col span={24}></Col>
                                                            </Row>



                                                            <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 20 }}>
                                                                <div style={{ marginRight: 10 }}>
                                                                    <NJVLabel label="NRC Front Image" />
                                                                    {
                                                                        data.senderNRCFrontImageUrl ?
                                                                            <>

                                                                                <Image
                                                                                    style={{
                                                                                        marginTop: 5,
                                                                                        borderRadius: 9,
                                                                                        cursor: 'pointer',
                                                                                        padding: 10,
                                                                                        border: '1px solid grey'
                                                                                    }}
                                                                                    onClick={() => this.setState({ viewPhoto: true })}
                                                                                    src={data.senderNRCFrontImageUrl}
                                                                                    width={200}
                                                                                />
                                                                            </>

                                                                            :
                                                                            <div
                                                                                style={{
                                                                                    marginTop: 5,
                                                                                    borderRadius: 9,
                                                                                    cursor: 'pointer',
                                                                                    padding: 10,
                                                                                    backgroundColor: '#f1f1f1',
                                                                                    border: '1px solid #f1f1f1',
                                                                                    width: 200,
                                                                                    height: 200,
                                                                                    display: 'flex',
                                                                                    justifyContent: 'center',
                                                                                    alignItems: 'center'
                                                                                }}
                                                                            >
                                                                                <span style={{ fontWeight: '600', color: 'grey' }}>No Image</span>
                                                                            </div>
                                                                    }

                                                                </div>

                                                                <div style={{ marginRight: 10 }}>
                                                                    <NJVLabel label="NRC Back Image" />
                                                                    {
                                                                        data.senderNRCBackImageUrl ?
                                                                            <Image
                                                                                style={{
                                                                                    marginTop: 5,
                                                                                    borderRadius: 9,
                                                                                    cursor: 'pointer',
                                                                                    padding: 10,
                                                                                    border: '1px solid grey'
                                                                                }}
                                                                                onClick={() => this.setState({ viewPhoto: true })}
                                                                                src={data.senderNRCBackImageUrl}
                                                                                width={200}
                                                                            />
                                                                            :
                                                                            <div
                                                                                style={{
                                                                                    marginTop: 5,
                                                                                    borderRadius: 9,
                                                                                    cursor: 'pointer',
                                                                                    padding: 10,
                                                                                    backgroundColor: '#f1f1f1',
                                                                                    border: '1px solid #f1f1f1',
                                                                                    width: 200,
                                                                                    height: 200,
                                                                                    display: 'flex',
                                                                                    justifyContent: 'center',
                                                                                    alignItems: 'center'
                                                                                }}
                                                                            >
                                                                                <span style={{ fontWeight: '600', color: 'grey' }}>No Image</span>
                                                                            </div>
                                                                    }
                                                                </div>
                                                                <div style={{ marginRight: 10 }}>
                                                                    <NJVLabel label="Product Image" />
                                                                    {
                                                                        data.productImageUrl ?

                                                                            <Image
                                                                                style={{
                                                                                    marginTop: 5,
                                                                                    borderRadius: 9,
                                                                                    cursor: 'pointer',
                                                                                    padding: 10,
                                                                                    border: '1px solid grey'
                                                                                }}
                                                                                onClick={() => this.setState({ viewPhoto: true })}
                                                                                src={data.productImageUrl}
                                                                                width={200}
                                                                            />
                                                                            :
                                                                            <div
                                                                                style={{
                                                                                    marginTop: 5,
                                                                                    borderRadius: 9,
                                                                                    cursor: 'pointer',
                                                                                    padding: 10,
                                                                                    backgroundColor: '#f1f1f1',
                                                                                    border: '1px solid #f1f1f1',
                                                                                    width: 200,
                                                                                    height: 200,
                                                                                    display: 'flex',
                                                                                    justifyContent: 'center',
                                                                                    alignItems: 'center'
                                                                                }}
                                                                            >
                                                                                <span style={{ fontWeight: '600', color: 'grey' }}>No Image</span>
                                                                            </div>
                                                                    }
                                                                </div>
                                                                <div>
                                                                    <NJVLabel label="Package Image" />
                                                                    {
                                                                        data.packageImageUrl ?
                                                                            <Image
                                                                                style={{
                                                                                    marginTop: 5,
                                                                                    borderRadius: 9,
                                                                                    cursor: 'pointer',
                                                                                    padding: 10,
                                                                                    border: '1px solid grey'
                                                                                }}
                                                                                onClick={() => this.setState({ viewPhoto: true })}
                                                                                src={data.packageImageUrl}
                                                                                width={200}
                                                                            />
                                                                            :
                                                                            <div
                                                                                style={{
                                                                                    marginTop: 5,
                                                                                    borderRadius: 9,
                                                                                    cursor: 'pointer',
                                                                                    padding: 10,
                                                                                    backgroundColor: '#f1f1f1',
                                                                                    border: '1px solid #f1f1f1',
                                                                                    width: 200,
                                                                                    height: 200,
                                                                                    display: 'flex',
                                                                                    justifyContent: 'center',
                                                                                    alignItems: 'center'
                                                                                }}
                                                                            >
                                                                                <span style={{ fontWeight: '600', color: 'grey' }}>No Image</span>
                                                                            </div>
                                                                    }


                                                                </div>
                                                            </div>
                                                            <br />
                                                            {
                                                                data.orderType === 'Bus Gate' ?
                                                                    <>
                                                                        <NJVLabel label="Bus Gate Voucher" />
                                                                        {
                                                                            data.busGateVoucherImagePath ?
                                                                                <Image
                                                                                    style={{
                                                                                        marginTop: 5,
                                                                                        borderRadius: 9,
                                                                                        cursor: 'pointer'
                                                                                    }}
                                                                                    onClick={() => this.setState({ viewPhoto: true })}
                                                                                    src={data.busGateVoucherImagePath}
                                                                                    // preview={false}
                                                                                    width={200}
                                                                                />
                                                                                :
                                                                                <div
                                                                                    style={{
                                                                                        marginTop: 5,
                                                                                        borderRadius: 9,
                                                                                        cursor: 'pointer',
                                                                                        padding: 10,
                                                                                        backgroundColor: '#f1f1f1',
                                                                                        border: '1px solid #f1f1f1',
                                                                                        width: 200,
                                                                                        height: 200,
                                                                                        display: 'flex',
                                                                                        justifyContent: 'center',
                                                                                        alignItems: 'center'
                                                                                    }}
                                                                                >
                                                                                    <span style={{ fontWeight: '600', color: 'grey' }}>No Image</span>
                                                                                </div>
                                                                        }
                                                                    </>
                                                                    :
                                                                    <></>
                                                            }


                                                        </NJVCard>
                                                    </Col>
                                                    <Col span={12}>
                                                        {
                                                            data.orderStatus === 'PENDING_PICK_UP' && STAFF === LOC_getUserType() ?
                                                                <>
                                                                    <NJVSpacer height={50} />
                                                                    <NJVButton type="primary" style={{ backgroundColor: 'red' }} onClick={() => this.setState({ showOrderCancelConfirmModal: true })} text={<span style={{ color: 'white', fontWeight: '600' }}>Cancel Order</span>} />
                                                                    <NJVSpacer height={50} />
                                                                    <br />
                                                                    <br />
                                                                </>
                                                                :
                                                                <>
                                                                </>
                                                        }
                                                    </Col>
                                                    <Col span={12} style={{ textAlign: 'right' }}>
                                                        <NJVSpacer height={50} />
                                                        <ReactToPrint

                                                            trigger={() => <NJVButton size='large' type="primary" style={{ backgroundColor: Colors.primary, width: 150 }} text="Print AWB" />}
                                                            content={() => this.refComponent}
                                                        />
                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                                                        <div style={{ height: 20 }}>
                                                            <div
                                                                key={2}
                                                                ref={(el) => this.refComponent = el}
                                                                style={customSizeStyles}>
                                                                <AirWayBillSlip
                                                                    key={1}
                                                                    senderName={data.senderName}
                                                                    senderPhone={data.senderPhoneNumber}
                                                                    senderAddress={data.fromAddress}
                                                                    receiverName={data.recipientName}
                                                                    receiverPhone={data.recipientPhoneNumber}
                                                                    receiverAddress={data.recipientAddress}
                                                                    trackId={data.trackingNumber}
                                                                    createDate={data.createdDate}
                                                                    parcelAmount={data.parcelAmount}
                                                                    deliveryCharges={data.deliveryCharges}
                                                                    cod={data.codAmount}
                                                                    note={data.note}
                                                                    sortingCode={data.sortingCode}
                                                                    storeName={data?.shipper?.fullName}
                                                                />
                                                            </div>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </div>
                                            :
                                            <Row>
                                                <Col span={24} style={{ display: 'flex', justifyContent: 'center' }}>
                                                    <Lottie
                                                        style={{ height: '200px' }}
                                                        animationData={NoDataFoundAnimation}
                                                        loop={true}
                                                        autoplay={true}
                                                    />
                                                </Col>
                                                <Col span={24} style={{ textAlign: 'center', marginTop: 20 }}>
                                                    <div style={{ fontSize: 16, fontWeight: '600', marginBottom: 10 }}>
                                                        {`No order found`}
                                                    </div>
                                                </Col>
                                            </Row>
                                    }
                                </>
                        }
                    </Col>
                </Row >
            </>
        )
    }

}

export default compose(withRouter)(OrderDetail)