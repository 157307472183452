import { createSlice } from '@reduxjs/toolkit'

export const cookieSlice = createSlice({
    name: 'cookie',
    initialState: {
        currentRoute: '',
        currentSelectedMenu: 1
    },
    reducers: {
        updateCurrentRoute: (state, action) => {
            state.currentRoute = action.payload
        },
        updateSelectedMenu: (state, action) => {
            state.currentSelectedMenu = action.payload
        },
        clearCookie: (state) => {
            state.currentRoute = ''
            state.currentSelectedMenu = 1
        }
    },
})

export const { updateCurrentRoute, clearCookie, updateSelectedMenu } = cookieSlice.actions
export const currentRoute = (state) => state.cookie.currentRoute
export const currentSelectedMenu = (state) => state?.cookie?.currentSelectedMenu
export default cookieSlice.reducer
